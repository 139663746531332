<template>
  <div class="survey">
    <AppContainer>
      <template v-if="isLoading">
        <AppLoading />
      </template>
      <div class="content" v-else>
        <button class="app-modal__close mb-3" @click="closeModal()">
          <img
            class="app-modal__close--image"
            :src="require('@/assets/image/icon-close-white.svg')"
            alt="close popup"
          />
        </button>
        <div class="user-summary text-center mb-3" v-if="user_info">
          <UserSummary
            :propsUser="user_info"
            :propsType="typeSingle"
            :propsSex="2"
            :propsShadow="true"
            :propsButton="false"
            @update-like="changeLike"
          />
        </div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form class="form" @submit.prevent="handleSubmit(registerRoundlog)">
            <div action="" class="form-evaluate">
              <div class="send-message-woman text-left f-w3">
                <ValidationProvider
                  name="コメント"
                  rules="required|max:144"
                  v-slot="{ errors }"
                >
                  <input
                    class="input-women"
                    type="text"
                    id=""
                    name=""
                    v-model="comment"
                    placeholder="コメントしてあげましょう！"
                    @blur="blur()"
                    @keyup="changeComment"
                  />
                  <br />
                  <span class="error text-left">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>
              <div class="evaluate position-relative">
                <div class="evaluate-title text-center f-w6">
                  お相手のスコア、満足度を教えてください
                </div>
                <div class="option">
                  <div class="option-evaluation-title">スコア</div>
                  <div class="option-opponent-score">満足度</div>
                  <div class="option-prego-app">
                    <div>アプリ</div>
                    <div>の評価</div>
                  </div>
                </div>
                <div class="slider-score">
                  <SliderScore
                    @score="score($event, 1)"
                    :dataSlide="dataScore"
                    :defaultId="51"
                    :id_item="'data-score'"
                  />
                </div>
                <div class="evaluation-star">
                  <SliderScore
                    @score="score($event, 2)"
                    :dataSlide="dataSurvey"
                    :defaultId="5"
                    :id_item="'data-evaluation'"
                  />
                </div>
                <div class="app-evaluation-star">
                  <SliderScore
                    @score="score($event, 3)"
                    :dataSlide="dataPregoApp"
                    :defaultId="11"
                    :id_item="'data-app-evaluation'"
                  />
                </div>
                <button
                  class="
                    position-absolute
                    f-w6
                    button-submit
                    d-flex
                    align-items-center
                    justify-content-center
                  "
                  type="submit"
                >
                  送信
                </button>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </AppContainer>
    <ModalLayout ref="survey-popup" :propsClose="true">
      <SurveyPopup />
    </ModalLayout>
  </div>
</template>

<script>
import SliderScore from "@/views/Common/Roundlog/sliderScore";
import UserSummary from "@/components/UserSummary.vue";
import { mapGetters } from "vuex";
import SurveyPopup from "@/components/SurveyPopup";
import service from "@/utils/axios";
import { USER_URL } from "@/api/api";
export default {
  name: "Survey",
  components: {
    SliderScore,
    UserSummary,
    SurveyPopup
  },
  computed: {
    ...mapGetters({
      isLoading: "common/isLoading"
    })
  },
  metaInfo() {
    return {
      title: "調査",
      titleTemplate: "%s | PreGo"
    };
  },
  async created() {
    this.$store.dispatch("common/setIsLoading", true);
    await this.$store.dispatch(
      "userApp/getInfoUser",
      this.$route.params.userId
    );
    this.user_info = this.$store.getters["userApp/user"].user;
    for (let i = 0; i < this.user_info.image_urls.length; i++) {
      if (this.user_info.image_urls[i].type == 1) {
        if (this.user_info.image_urls[i].display_order == 1) {
          this.user_info.image_url = this.user_info.image_urls[i].image_url;
        }
      }
    }
    await this.getScore();
    this.$store.dispatch("common/setIsLoading", false);
  },
  data() {
    return {
      data_score: 51,
      evaluation: 5,
      app_evaluation: 5,
      user_info: null,
      comment: null,
      typeSingle: { type: "survey" },
      dataScore: [],
      dataSurvey: ["", 60, 70, 80, 90, 100, ""],
      dataPregoApp: []
    };
  },
  methods: {
    changeComment(event) {
      this.comment = event.target.value;
    },
    closeModal() {
      this.$router.go(-1);
    },
    getScore() {
      this.dataScore.push("");
      for (let j = 50; j <= 150; j++) {
        this.dataScore.push(j.toString());
      }
      this.dataScore.push("150以上");
      this.dataScore.push("");
      this.dataPregoApp.push("");
      for (let i = 0; i <= 100; i += 10) {
        this.dataPregoApp.push(i);
      }
      this.dataPregoApp.push("");
    },
    async registerRoundlog() {
      let params = {};
      // for (let i = 0; i < listOptions.length; i++) {
      params.score =
        this.dataScore[this.data_score] == "150以上"
          ? 150
          : Number(this.dataScore[this.data_score]);
      params.evaluation = this.dataSurvey[this.evaluation];
      params.app_evaluation = this.dataPregoApp[this.app_evaluation];
      params.comment = this.comment;
      params.user_id = this.$route.params.userId;
      this.$root.$refs.loading.start();
      await this.$store
        .dispatch("roundlog/rounglogRegister", {
          ad_id: this.$route.params.adId,
          params: params
        })
        .then(() => {
          this.$root.$refs.loading.finish();
          this.$refs["survey-popup"].openModal();
        })
        .catch(error => {
          this.$root.$refs.loading.finish();
          this.$toast(error.response.data.error.message, "通知", "danger");
          throw error;
        });
    },
    blur() {
      this.comment = this.comment.trim();
    },
    like(e, number) {
      let star = Number(e) + 1;
      if (number == 1) {
        this.evaluation = star;
      } else if (number == 2) {
        this.app_evaluation = star;
      }
    },
    score(e, number) {
      if (number == 1) this.data_score = Number(e);
      else if (number == 2) {
        this.evaluation = Number(e);
      } else {
        this.app_evaluation = Number(e);
      }
    },
    changeLike(data) {
      service({
        url: USER_URL + "/favorite",
        method: "POST",
        data: {
          user_id: data.userId,
          is_favorite: data.isFavorite === 1 ? 0 : 1
        }
      }).then(response => {
        if (response.data.success === true) {
          this.user_info.is_favorite = data.isFavorite === 1 ? 0 : 1;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/common/survey.scss";
</style>
<style lang="scss" scoped>
.slider-score {
  position: absolute;
  width: 220px;
  top: 85px;
  left: 63%;
  transform: translate(-63%);
}
.content {
  background-color: #000;
  min-height: 100vh;
}
.app-modal__close {
  background-color: #000;
  box-shadow: none;
  outline: none;
  position: absolute;
  top: 70px;
  right: 300px;
  &--image {
    width: 22px;
    height: 22px;
  }
}
.button-submit {
  background-color: unset;
  outline: none;
  box-shadow: none;
}
@media screen and (max-width: 1080px) {
  .app-modal__close {
    top: 10px;
    right: 10px;
    &--image {
      width: 14px;
      height: 14px;
    }
  }
}
@media screen and (min-width: 1080px) {
  .slider-score {
    position: absolute;
    width: 250px;
    top: 103px;
    left: 73%;
    transform: translate(-73%);
  }
}
</style>
